import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Typography, useTheme } from '@zydalabs/zac-react';
import { GiftIcon } from '@zydalabs/zac-icons-react';

import { context as localeContext } from 'context/locale';
import * as translations from 'constants/translations';

const OrderDetailsGift = ({ order }) => {
  const { direction, translate } = useContext(localeContext);
  const { colors } = useTheme();

  const { userData } = order || {};
  const { recipient } = userData || {};
  const { name, phoneNumber, giftNotes } = recipient || {};

  return (
    <>
      <div className="mt-4 border-t border-gray-200 pt-4 mb-3">
        <Typography variant="heading16">{translate(translations.RECIPIENT_INFORMATION)}</Typography>
      </div>
      <div className="flex gap-2 items-start">
        <GiftIcon width="24px" color="#c1c1c1" />
        <div className="flex w-full flex-col">
          <Typography>{name}</Typography>
          <a href={`tel:${phoneNumber}`}>
            <Typography cursor="pointer" color={colors.accent.primary}>
              {phoneNumber}
            </Typography>
          </a>
          {giftNotes && (
            <div
              style={{ direction, backgroundColor: colors.blue[100] }}
              className="w-fit flex items-center mt-2 mb-3 px-2 py-1 bg-yellow-50 gap-2"
            >
              <div className="flex flex-shrink-0">
                <Typography variant="heading14" color={colors.blue.primary}>
                  {`${translate(translations.GIFT_NOTE)}:`}
                </Typography>
              </div>
              <Typography variant="body14" color={colors.blue.primary}>
                {giftNotes}
              </Typography>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

OrderDetailsGift.propTypes = {
  order: PropTypes.shape({
    gift: PropTypes.string,
    userData: PropTypes.shape({
      recipient: PropTypes.shape({
        name: PropTypes.string,
        phoneNumber: PropTypes.number,
        giftNotes: PropTypes.string,
      }),
    }),
  }),
};
export default OrderDetailsGift;
