import React, { useContext } from 'react';
import { Typography, useTheme } from '@zydalabs/zac-react';
import { PhoneCallIcon, UserIcon } from '@zydalabs/zac-icons-react';

import * as translations from 'constants/translations';
import { context as localeContext } from 'context/locale';

function OrderDetailsUserInfo({ order, cancelOrder }) {
  const { translate } = useContext(localeContext);
  const { colors } = useTheme();

  const { customerName, number, userData } = order || {};
  const { phoneNumber, email } = userData || {};

  return (
    <div>
      <div className="flex justify-between">
        <div className="flex flex-col">
          <Typography variant="heading20">{customerName}</Typography>
          <Typography variant="element14" color={colors.positive.secondary}>
            #{number}
          </Typography>
        </div>
        {cancelOrder}
      </div>
      <div className="flex justify-between items-center mt-4 flex-wrap">
        <div className="flex items-center gap-2">
          <PhoneCallIcon width="20px" height="20px" color={colors.blue.primary} />
          <a href={`tel:${phoneNumber}`}>
            <Typography color={colors.blue.primary} cursor="pointer">
              {phoneNumber}
            </Typography>
          </a>
        </div>
        <div className="flex items-center gap-2">
          <UserIcon width="25px" height="25px" />
          <Typography color={email ? 'black' : colors.positive.secondary}>
            {email || translate(translations.NO_EMAIL_PROVIDED)}
          </Typography>
        </div>
      </div>
    </div>
  );
}

export default OrderDetailsUserInfo;
