import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@zydalabs/zac-react';

import { context as localeContext } from 'context/locale';
import { PAYMENT_METHODS_ENUMS } from 'components/common/orders/Frame/utils';
import * as translations from 'constants/translations';
import PaidThrough from '../PaidThrough';

const OrderDetailsPayments = ({ paidThrough, paidByWallet = 0, paidByCreditCard = 0, currency }) => {
  const { translate } = useContext(localeContext);

  return (
    <div className="flex w-full justify-between">
      <Typography variant="heading16">{translate(translations.PAYMENT_METHODS)}</Typography>
      {paidThrough === PAYMENT_METHODS_ENUMS.WALLET_AND_CREDIT_CARD ? (
        <div>
          <div className="flex justify-between">
            <Typography variant="element16">{translate(translations.CREDIT_CARD)}</Typography>
            <Typography variant="element16">{`${translate(currency)} ${paidByCreditCard.toFixed(
              currency.decimals,
            )}`}</Typography>
          </div>
          <div className="flex w-full pt-2 gap-3 justify-between">
            <Typography variant="element16">{translate(translations.ORDERFAST_WALLET)}</Typography>
            <Typography variant="element16">{`${translate(currency)} ${paidByWallet.toFixed(
              currency.decimals,
            )}`}</Typography>
          </div>
        </div>
      ) : (
        <div className="flex">
          <Typography variant="element16">
            <PaidThrough paidThrough={paidThrough} />
          </Typography>
        </div>
      )}
    </div>
  );
};

OrderDetailsPayments.propTypes = {
  paidThrough: PropTypes.string.isRequired,
  paidByWallet: PropTypes.number,
  paidByCreditCard: PropTypes.number,
  currency: PropTypes.string,
};

export default OrderDetailsPayments;
