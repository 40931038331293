import React, { useContext } from 'react';
import { Typography } from '@zydalabs/zac-react';
import PropTypes from 'prop-types';

import { context as localeContext } from 'context/locale';
import * as translations from 'constants/translations';

const DeliveryFees = ({ order, currency }) => {
  const { translate, direction } = useContext(localeContext);

  const { engageFees, feastFees } = order || {};
  const feesArray = [
    { id: 2, title: translate(translations.ENGAGE_FEES), amount: engageFees },
    { id: 3, title: translate(translations.DYMA_FEES), amount: feastFees },
  ];
  const CheckIfAnyFeesHasValue = feesArray.reduce((acc, val) => acc + val.amount, 0);

  return (
    <>
      {CheckIfAnyFeesHasValue !== 0 && (
        <div className="w-full bg-gray-100 py-3 px-4" style={{ direction }}>
          <Typography variant="heading16">{translate(translations.FEES)}</Typography>
          {feesArray?.map(
            fee =>
              !!fee.amount && (
                <div key={fee.id} className="flex justify-between mt-1">
                  <Typography>{fee.title}</Typography>
                  <Typography>
                    {translate(currency)}&nbsp;
                    {fee.amount.toFixed(currency.decimals)}
                  </Typography>
                </div>
              ),
          )}
        </div>
      )}
    </>
  );
};

export default DeliveryFees;

DeliveryFees.propTypes = {
  order: PropTypes.shape({ verdFees: PropTypes.number, engageFees: PropTypes.number, feastFees: PropTypes.number }),
  currency: PropTypes.shape({
    decimals: PropTypes.number.isRequired,
    titleAr: PropTypes.string.isRequired,
    titleEn: PropTypes.string.isRequired,
  }),
};
