import React from 'react';

import OrderDetailsUmbrella from './OrderDetailsUmbrella';
import OrderDetailsGift from './OrderDetailsGift';
import OrderDetailsUserAddress from './OrderDetailsUserAddress';
import OrderDetailsUserCar from './OrderDetailsUserCar';
import OrderDetailsUserInfo from './OrderDetailsUserInfo';

const ContactInformation = ({ order, cancelOrder }) => {
  const { userData, deliveryType, beachUmbrella, gift } = order || {};
  const { address, recipient, car } = userData || {};

  return (
    <>
      <OrderDetailsUserInfo order={order} cancelOrder={cancelOrder} />
      <div>
        {recipient && gift && <OrderDetailsGift order={order} />}
        {beachUmbrella && <OrderDetailsUmbrella order={order} />}
        {address && <OrderDetailsUserAddress order={order} />}
        {userData && car && deliveryType === 'pickup' && <OrderDetailsUserCar order={order} />}
      </div>
    </>
  );
};

export default ContactInformation;
