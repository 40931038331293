import React, { useContext } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Typography } from '@zydalabs/zac-react';

import { context as localeContext } from 'context/locale';
import * as translations from 'constants/translations';

const OrderDetailsUserCar = ({ order }) => {
  const { translate } = useContext(localeContext);
  const { userData } = order || {};
  const { car } = userData || {};
  const { make, model, color, licenseNumber } = car || {};

  return (
    <div className="mt-4 flex gap-4 flex-col pt-4 border-t border-gray-200">
      <Typography variant="heading16">{translate(translations.CAR_INFO)}</Typography>
      <div className={cx('w-full flex gap-2 flex-col')}>
        {make && <Typography variant="body14">{`${translate(translations.CAR_MODEL)}: ${make}`}</Typography>}
        {model && <Typography variant="body14">{model}</Typography>}
        {color && <Typography variant="body14">{`${translate(translations.CAR_COLOR)}: ${color}`}</Typography>}
        {licenseNumber && (
          <Typography variant="body14">{`${translate(translations.CAR_LICENSE)}: ${licenseNumber}`}</Typography>
        )}
      </div>
    </div>
  );
};

OrderDetailsUserCar.propTypes = {
  order: PropTypes.shape({
    userData: PropTypes.shape({
      car: PropTypes.shape({
        make: PropTypes.string,
        model: PropTypes.string,
        color: PropTypes.string,
        licenseNumber: PropTypes.string,
      }),
    }),
  }),
};

export default OrderDetailsUserCar;
