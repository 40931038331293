import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { context as localeContext } from 'context/locale';
import * as translations from 'constants/translations';
import { Typography } from '@zydalabs/zac-react';

const OrderDetailsCard = ({
  quantity,
  itemQuantity,
  optionName,
  price,
  currency,
  numberOfEach,
  isGift = false,
  shouldHidePricing = false,
}) => {
  const { translate, direction } = useContext(localeContext);
  const quantityText = [`${quantity}x`, `x${quantity}`];
  return (
    <div className="flex justify-between items-center" style={{ direction }}>
      <div className="flex flex-col">
        <div className="flex gap-2">
          <Typography>{translate(quantityText)}</Typography>

          <div className="flex flex-col text-xs">
            <Typography>{translate(optionName)}</Typography>
            {itemQuantity > 1 && <Typography>{`${numberOfEach} ${translate(translations.FOR_EACH)}`}</Typography>}
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-end items-end flex-shrink-0">
        {!isGift && price && !shouldHidePricing ? (
          <>
            <Typography>{`${translate(currency)} ${price.toFixed(currency.decimals) * quantity}`}</Typography>
            {quantity > 1 && (
              <Typography>{`(${translate(quantityText)} ${translate(currency)} ${price.toFixed(
                currency.decimals,
              )})`}</Typography>
            )}
          </>
        ) : (
          <Typography>--</Typography>
        )}
      </div>
    </div>
  );
};
OrderDetailsCard.propTypes = {
  quantity: PropTypes.number.isRequired,
  itemQuantity: PropTypes.number.isRequired,
  optionName: PropTypes.shape({
    titleAr: PropTypes.string,
    titleEn: PropTypes.string,
  }).isRequired,
  numberOfEach: PropTypes.number.isRequired,
  price: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
  isGift: PropTypes.bool,
  shouldHidePricing: PropTypes.bool,
};

export default OrderDetailsCard;
