const addressMapOptions = {
  disableDefaultUI: true,
  streetViewControl: false,
  mapTypeControl: false,
  gestureHandling: 'greedy',
  styles: [
    {
      featureType: 'all',
      elementType: 'all',
      stylers: [
        {
          saturation: -100, // Desaturate the colors
        },
        {
          lightness: 50, // Adjust the lightness for a grayscale appearance
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry',
      stylers: [
        {
          visibility: 'simplified', // Simplify highway geometry
        },
        {
          color: '#d4d4d4', // Light gray color for highways
        },
      ],
    },
    {
      featureType: 'road.local',
      elementType: 'geometry',
      stylers: [
        {
          visibility: 'simplified', // Simplify local road geometry
        },
        {
          color: '#d4d4d4', // Light gray color for local roads
        },
      ],
    },
    {
      featureType: 'water',
      elementType: 'geometry',
      stylers: [
        {
          color: '#1da2d8',
        },
      ],
    },
    {
      featureType: 'poi',
      elementType: 'labels',
      stylers: [
        {
          visibility: 'off', // Hide Points of Interest labels
        },
      ],
    },
  ],
  draggable: true,
  fullscreenControl: false,
};

export default addressMapOptions;
