import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import SearchInput from 'common/components/SearchInput';
import { useMobile } from 'hooks';
import { SEARCH_ORDERS, SEARCH_ORDERS_MOBILE } from 'constants/translations';
import { allOrders, currentOrders } from 'paths.js';
import { changePage, FRAME_ENUM } from './utils';

const OrderFrameSearchInput = ({ searchValue = '', setSearchValue, setQuery, query, replace, lang }) => {
  const searchTimeout = useRef();
  const isMobile = useMobile();

  const handleOnChange = e => {
    // TODO: to be moved to a separate utility function
    setSearchValue(e.target.value);
    clearTimeout(searchTimeout.current);
    const { value } = e.target;
    const appendingQuery = {
      search_value: value,
      search_status: FRAME_ENUM.ALL,
    };
    searchTimeout.current = setTimeout(() => {
      setQuery(appendingQuery);
      if (value && !query.search_value) {
        changePage(replace(allOrders, { omitQuery: true }), appendingQuery);
      }

      if (!value && query.search_value) {
        changePage(replace(currentOrders, { omitQuery: true }), appendingQuery);
      }
    }, 500);
  };

  return (
    <SearchInput
      searchValue={searchValue}
      setSearchValue={handleOnChange}
      bgColor="bg-gray-100"
      placeholder={
        isMobile
          ? lang === 'en'
            ? SEARCH_ORDERS_MOBILE[0]
            : SEARCH_ORDERS_MOBILE[1]
          : lang === 'en'
          ? SEARCH_ORDERS[0]
          : SEARCH_ORDERS[1]
      }
    />
  );
};

export default OrderFrameSearchInput;

OrderFrameSearchInput.propTypes = {
  setSearchValue: PropTypes.func.isRequired,
  searchValue: PropTypes.string,
  replace: PropTypes.func.isRequired,
  setQuery: PropTypes.func.isRequired,
  query: PropTypes.shape({ search_value: PropTypes.string }).isRequired,
  lang: PropTypes.oneOf(['en', 'ar']).isRequired,
};
