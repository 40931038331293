import React, { useContext } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Typography, useTheme } from '@zydalabs/zac-react';

import { Text } from 'components/service';
import { useMobile } from 'hooks';
import * as translations from 'constants/translations';
import { context as localeContext } from 'context/locale';

const OrderDate = ({ date, timeZone }) => {
  const { lang } = useContext(localeContext);
  const { colors } = useTheme();
  const isMobile = useMobile();

  const direction = lang === 'ar' ? 'rtl' : 'ltr';

  return moment(date).isSame(new Date(), 'day') ? (
    // same day
    <span
      className={cx('flex inline-block', isMobile ? 'w-full items-center' : 'items-start w-20 flex-col')}
      style={{ direction }}
    >
      <Typography
        variant="element14"
        marginRight={isMobile && lang === 'en' && 5}
        marginLeft={isMobile && lang === 'ar' && 5}
        marginBottom={isMobile && 3}
        color={colors.positive.secondary}
      >
        <Text value={translations.TODAY} />
      </Typography>
      <Typography variant="element12">
        {moment
          .tz(date, timeZone)
          .locale(lang === 'ar' ? 'ar' : 'en-gb')
          .format('h:mm A')}
      </Typography>
    </span>
  ) : moment(date).isSame(moment().subtract(1, 'days'), 'day') ? (
    // yesterday
    <span
      className={cx('flex inline-block', isMobile ? 'items-center w-full' : 'items-start w-20 flex-col')}
      style={{ direction }}
    >
      <Typography
        variant="element14"
        marginRight={isMobile && lang === 'en' && 5}
        marginLeft={isMobile && lang === 'ar' && 5}
        marginBottom={isMobile && 3}
        color={colors.positive.secondary}
      >
        <Text value={translations.YESTERDAY} />
      </Typography>
      <Typography variant="element12" color={!isMobile && colors.yellow[600]}>
        {moment(date)
          .tz(timeZone)
          .locale(lang === 'ar' ? 'ar' : 'en-gb')
          .format('h:mm A')}
      </Typography>
    </span>
  ) : (
    // other
    <span
      className={cx('flex inline-block', isMobile ? 'items-center w-full' : 'items-start flex-col')}
      style={{ direction }}
    >
      <Typography
        variant="element14"
        marginRight={isMobile && lang === 'en' && 5}
        marginLeft={isMobile && lang === 'ar' && 5}
        marginBottom={3}
        color={colors.positive.secondary}
      >
        {isMobile
          ? moment
              .tz(date, timeZone)
              .locale(lang === 'ar' ? 'ar' : 'en-gb')
              .format('DD MMM')
          : moment
              .tz(date, timeZone)
              .locale(lang === 'ar' ? 'ar' : 'en-gb')
              .format('DD MMM YYYY')}
      </Typography>
      <Typography variant="element12" color={!isMobile && colors.red[600]}>
        {moment
          .tz(date, timeZone)
          .locale(lang === 'ar' ? 'ar' : 'en-gb')
          .format('h:mm A')}
      </Typography>
    </span>
  );
};

OrderDate.propTypes = {
  date: PropTypes.string,
  timeZone: PropTypes.string,
};
export default OrderDate;
