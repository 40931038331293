import React, { useContext } from 'react';
import { Marker, InfoWindow } from '@react-google-maps/api';

import './style.css';
import { context as localeContext } from 'context/locale';
import { GoogleMapContainer } from 'components/kit';
import MapMarkerGreen from 'assets/map-marker-green.svg';
import MapMarkerOrange from 'assets/map-marker-orange.svg';
import addressMapOptions from './addressMapOptions';

const AddressMap = ({ order }) => {
  const { lang } = useContext(localeContext);

  const isArabic = lang === 'ar';
  const { userData, branchData } = order || {};
  const { address, name: userName } = userData || {};
  const { area: { lat: areaLat, lng: areaLng } = {} } = address && address?.area ? address : {};
  const { lat, lng } = address || {};
  const { lat: storeLat, lng: storeLng, titleEn, titleAr } = branchData || {};
  const userLocation = { lat: parseFloat(lat || areaLat), lng: parseFloat(lng || areaLng) };
  const storeLocation = { lat: storeLat, lng: storeLng };

  const markers = [
    { id: 1, name: userName, location: userLocation, marker: MapMarkerGreen },
    { id: 2, name: isArabic ? titleAr : titleEn, location: storeLocation, marker: MapMarkerOrange },
  ];

  return (
    <div className="mt-4">
      <GoogleMapContainer
        onLoad={map => {
          const bounds = new window.google.maps.LatLngBounds();
          markers.forEach(marker => {
            bounds.extend({ lat: marker.location.lat, lng: marker.location.lng });
          });
          map.fitBounds(bounds);
        }}
        mapContainerClassName="h-80"
        options={addressMapOptions}
      >
        {markers.map(marker => (
          <Marker key={marker.id} position={marker.location} options={{ icon: marker.marker }}>
            <InfoWindow position={marker.location}>
              <div>{marker.name}</div>
            </InfoWindow>
          </Marker>
        ))}
      </GoogleMapContainer>
    </div>
  );
};

export default AddressMap;
