import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@zydalabs/zac-react';

import { context as localeContext } from 'context/locale';
import * as translations from 'constants/translations';

const OrderDetailsUmbrella = ({ order }) => {
  const { translate } = useContext(localeContext);

  return (
    <div className="mt-4 pt-4 border-t border-gray-200">
      <Typography variant="heading16">{translate(translations.UMBRELLA_DETAILS)}</Typography>
      <div className="w-full mt-4 flex flex-col">
        <Typography>{`${translate(translations.UMBRELLA_NUMBER)} ${order.beachUmbrella?.number}`}</Typography>
        {order.beachUmbrella?.specialDirections && (
          <Typography>{`${translate(translations.UMBRELLA_INSTRUCTION)} ${
            order.beachUmbrella?.specialDirections
          }`}</Typography>
        )}
      </div>
    </div>
  );
};

OrderDetailsUmbrella.propTypes = {
  order: PropTypes.shape({
    beachUmbrella: PropTypes.shape({
      number: PropTypes.string,
      specialDirections: PropTypes.string,
    }),
  }),
};
export default OrderDetailsUmbrella;
