import React from 'react';

const ShimmerCircle = () => {
  return (
    <div className="animate-pulse relative h-full w-full">
      <div className="rounded-full bg-gray-400 absolute h-full w-full"></div>
    </div>
  );
};

export default ShimmerCircle;
