import React, { useContext } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { Button } from 'components/kit';
import {
  FILTER,
  PAYMENT_TYPE_ORDER,
  PAYMENT_NOT_AVAILABLE,
  DATE_RANGE,
  AREA,
  AREAS_NOT_AVAILABLE,
  ORDER_TYPE,
  ZONE,
  NO_DELIVERY_ZONE,
} from 'constants/translations';
import { context as userContext } from 'context/user';
import { Text } from 'components/service';
import { ReactComponent as FilterIcon } from 'assets/filter-icon.svg';
import { CheckboxGroup, RadioList } from 'components/form/elements';
import { getRange, stringifyRange } from 'utils/date';
import { DELIVERY_BUSY_MODE } from 'components/common/branch/Busy/utils';
import { prepareAreasFilter, cleanPaymentMethods } from './utils';
import { deliveryTypes } from './data';
import MobileFilters from '../MobileFilters';

const OrderFrameMobileFilters = ({
  setMobileFiltersOpened,
  mobileFiltersOpened,
  setQuery,
  filters,
  paymentMethods,
  areasFilterData,
  lang,
  setFilters,
  filtersOpened,
  dateOptions,
  selectedStore,
}) => {
  const { settings } = useContext(userContext);
  const dropDownRenderValues = () => {
    // checking if store use new delivery zone system (useDeliveryzonesMs)
    // NOTE later we will migrate all stores to new system
    if (!settings.useDeliveryzonesMs) {
      return {
        dropdownLabel: AREA,
        dropdownPlaceHolder: AREAS_NOT_AVAILABLE,
        dropdownValue: filters.areas,
        dropdownOnChangeHandler: 'areas',
      };
    }
    return {
      dropdownLabel: ZONE,
      dropdownPlaceHolder: NO_DELIVERY_ZONE,
      dropdownValue: filters.delivery_zone_in,
      dropdownOnChangeHandler: 'delivery_zone_in',
    };
  };
  return (
    <>
      <div className={cx(lang === 'ar' ? 'ml-4' : 'mr-4')}>
        <Button
          style={{
            backgroundColor: filtersOpened ? '#d8dae5' : '#f7f8fc',
            flexDirection: lang === 'ar' ? 'row-reverse' : 'row',
          }}
          hFull
          icon={<FilterIcon />}
          onClick={() => {
            setMobileFiltersOpened(true);
          }}
        >
          <Text value={FILTER} />
        </Button>
      </div>

      <div className="z-50">
        <MobileFilters
          isVisible={mobileFiltersOpened}
          onClose={() => setMobileFiltersOpened(false)}
          onApply={() => {
            setQuery(filters);
            setMobileFiltersOpened(false);
          }}
        >
          <CheckboxGroup
            groupTitle={<Text value={PAYMENT_TYPE_ORDER} className="text-base text-gray-600" />}
            placeholder={<Text value={PAYMENT_NOT_AVAILABLE} />}
            value={filters.payment_methods}
            onChange={chosenPayments => {
              const cleanPayments = cleanPaymentMethods(chosenPayments.target.value);
              setFilters({
                ...filters,
                payment_methods: cleanPayments,
              });
            }}
            items={paymentMethods}
            containerStyle={{
              minWidth: '18rem',
            }}
            wFull
          />
          <RadioList
            groupTitle={<Text value={DATE_RANGE} className="text-base text-gray-600 px-4 pb-1 pt-3" />}
            value={filters.date}
            onChange={option => {
              if (option.target.value !== 'custom') {
                setFilters({
                  ...filters,
                  date: option.target.value,
                  range: stringifyRange(getRange(option.target.value, selectedStore.createdAt)),
                });
              }
            }}
            items={dateOptions}
            bodyElementClassName="px-4 py-2"
            hoverHighlight
            elementStyle={{
              direction: lang === 'ar' ? 'rtl' : 'unset',
            }}
            containerStyle={{
              marginTop: '0.5rem',
              minWidth: '18rem',
            }}
            elementClassName="px-2 py-1"
          />
          <CheckboxGroup
            groupTitle={<Text value={dropDownRenderValues().dropdownLabel} className="text-base text-gray-600" />}
            items={prepareAreasFilter(areasFilterData, lang)}
            placeholder={<Text value={dropDownRenderValues().dropdownPlaceHolder} />}
            value={dropDownRenderValues().dropdownValue}
            onChange={chosenAreas => {
              setFilters({
                ...filters,
                [dropDownRenderValues().dropdownOnChangeHandler]: chosenAreas.target.value,
              });
            }}
            containerStyle={{
              marginTop: '0.5rem',
              minWidth: '18rem',
              maxHeight: '20rem',
              overflowY: 'auto',
            }}
            wFull
          />
          <CheckboxGroup
            groupTitle={<Text value={ORDER_TYPE} className="text-base text-gray-600" />}
            items={deliveryTypes}
            value={filters.delivery_type}
            onChange={chosenTypes => {
              const isManualOrder = chosenTypes.target.value.includes(DELIVERY_BUSY_MODE.MANUAL);
              setFilters({
                ...filters,
                delivery_type: chosenTypes.target.value,
                is_manual_order: isManualOrder,
              });
            }}
            containerStyle={{
              marginTop: '0.5rem',
              minWidth: '18rem',
            }}
            wFull
          />
        </MobileFilters>
      </div>
    </>
  );
};

OrderFrameMobileFilters.propTypes = {
  filtersOpened: PropTypes.bool.isRequired,
  lang: PropTypes.oneOf(['en', 'ar']).isRequired,
  setMobileFiltersOpened: PropTypes.func.isRequired,
  mobileFiltersOpened: PropTypes.bool.isRequired,
  setFilters: PropTypes.func.isRequired,
  setQuery: PropTypes.func.isRequired,
};
export default OrderFrameMobileFilters;
