import React, { useContext } from 'react';
import * as paths from 'paths.js';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Link, Text } from 'components/service';
import { More, Modal, ToggleButton } from 'components/kit';
import { useReplaceParams, useSelectedStore } from 'hooks/index';
import useUpdateStoreDriver from 'service/hooks/verd/useUpdateStoreDriver';
import { context as localeContext } from 'context/locale';
import * as translations from 'constants/translations';
import { DeleteRider } from 'components/common/verd';
import { Typography } from '@zydalabs/zac-react';
import { RIDER_AVAILABILITY } from '../enums';

const RidersMobileList = ({ riders, handleRiderDeletion, mutate, setIsSomethingLoading }) => {
  const replace = useReplaceParams();
  const selectedStoreId = useSelectedStore();
  const { lang, translate } = useContext(localeContext);
  const updateStoreDriver = useUpdateStoreDriver();

  return (
    <div>
      {riders.map(rider => (
        <div className="flex justify-between mb-3 pb-3 border-b border-gray-300">
          <div>
            <Link
              className="flex"
              to={replace(paths.editRider, {
                params: { riderId: rider.id },
              })}
              key={rider.id}
            >
              <div>
                <div className="font-semibold">{rider.name}</div>
                <div className="text-gray-500 mb-1">{rider.jobType}</div>
                <div className="text-xs w-full">
                  {rider.email} {rider.email && '•'} {rider.phone}
                </div>
              </div>
            </Link>
            <div className={cx('flex justify-start items-center mt-2', lang === 'ar' ? '-mr-2' : '-ml-2')}>
              <ToggleButton
                value={rider.onShift}
                testId_openDropDown="rider-availability"
                testId_selectedData="rider-availability-name"
                testId_listData="rider-availability-list"
                name={`rider-availability ${rider.id}`}
                onChange={async newAvailability => {
                  setIsSomethingLoading(true);
                  await updateStoreDriver({
                    storeId: selectedStoreId,
                    id: rider.id,
                    onShift: newAvailability.target.value === RIDER_AVAILABILITY.AVAILABLE,
                  });
                  mutate().finally(() => {
                    setIsSomethingLoading(false);
                  });
                }}
              />
              <Typography variant="body14">
                {rider.onShift ? translate(translations.ON_DUTY) : translate(translations.OFF_DUTY)}
              </Typography>
            </div>
          </div>
          <Modal>
            {({ open, close }) => (
              <More
                kind="action"
                size="smIcon"
                iconSize="md"
                items={[
                  {
                    title: (
                      <Link
                        to={replace(paths.editRider, {
                          omitQuery: true,
                          params: { riderId: rider.id },
                        })}
                      >
                        <Text value={translations.EDIT} />
                      </Link>
                    ),
                    onClick: () => {
                      replace(paths.editRider, {
                        omitQuery: true,
                        params: { riderId: rider.id },
                      });
                    },
                  },
                  {
                    title: <Text value={translations.DELETE} />,
                    onClick: () => {
                      open({
                        title:
                          rider.assignedOrdersCount > 0
                            ? translate(translations.DELETE_RIDER_MODAL_TITLE, lang)
                            : rider.name,
                        body:
                          rider.assignedOrdersCount > 0 ? (
                            <Text className="px-4" value={translations.DELETE_RIDER_ERROR} payload={rider.name} />
                          ) : (
                            <DeleteRider rider={rider} onClose={close} handleRiderDeletion={handleRiderDeletion} />
                          ),
                        size: 'max-w-md',
                      });
                    },
                  },
                ]}
              />
            )}
          </Modal>
        </div>
      ))}
    </div>
  );
};

RidersMobileList.propTypes = {
  riders: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      email: PropTypes.string,
      phone: PropTypes.string,
      assignedOrdersCount: PropTypes.number,
      jobType: PropTypes.string,
      onShift: PropTypes.bool,
      branches: PropTypes.arrayOf(PropTypes.string),
    }),
  ),
};

export default RidersMobileList;
