import React, { useState, useRef, useContext, useEffect, createContext } from 'react';
import moment from 'moment';
import 'moment/locale/en-gb';
import 'moment/locale/ar';
import { useQuery, useLazyQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import momentPropTypes from 'react-moment-proptypes';

import { useReplaceParams, useQueryState, useMobile, useSelectedStore } from 'hooks/index';
import { ORDER_STATUS } from 'constants/order';
import { Modal, Tabs } from 'components/kit';
import { context as localeContext } from 'context/locale';
import { context as notificationsContext } from 'context/notifications';
import { context as newOrdersContext } from 'context/new-orders';
import { context as userContext } from 'context/user';
import { Text } from 'components/service';
import cx from 'classnames';
import * as translations from 'constants/translations';
import useClickOutside from 'hooks/useClickOutside';
import { replaceParams } from 'utils/index';
import { parseRange } from 'utils/date';
import translateBusyMode from 'common/utils/translateBusyMode';
import { useFetchDeliveryZonesShort, useFetchVerdDrivers } from 'service/hooks';
import { ORDERS, GET_PAYMENT_METHODS, RESTAURANT_AREAS } from './schemas';
import {
  calcBusyTime,
  FRAME_ENUM,
  handleNumberOfBusyBranches,
  Style,
  prepareAreasFilter,
  fetchOrderVariables,
  trimPaymentMethods,
} from './utils';
import Slideover from '../../dashboard/Layout/Slideover/Slideover';
import ManualOrder from '../ManualOrder/ManualOrder';
import OrderFrameBusyStatus from './OrderFrameBusyStatus';
import { fetchAllTabsItems, fetchDateOptions, initalOrderFilters, initialOrderQuery } from './data';
import OrderFrameSetAsBusyButton from './OrderFrameSetAsBusyButton';
import OrderFrameDesktopFilters from './OrderFrameDesktopFilters';
import OrderFrameSearchInput from './OrderFrameSearchInput';
import OrderFrameMobileFilters from './OrderFrameMobileFilters';
import OrderFrameSortByDropdown from './OrderFrameSortByDropdown';
import OrderFrameSelectionTags from './OrderFrameSelectionTags';
import OrderFrameSearchResultsFilter from './OrderFrameSearchResultsFilter';
import BranchesDropDown from '../../../../common/components/BranchesDropDown';

moment.locale('en-gb');

export const context = createContext();

const OrderFrameFilters = ({
  children,
  storeId,
  status,
  statuses,
  paymentStatus,
  paymentStatuses,
  setBusyTime,
  setAllBranches,
  busyTime,
  setBusyMode,
  busyMode,
  showOrderStatus,
  setExternalVariables,
  setCanExportWFilters,
  canExportWFilters,
  allBranches,
  isManualOrderSlideoverOpened,
  setManualOrderSlideoverOpened,
}) => {
  const replace = useReplaceParams();
  const { lang, direction, translate } = useContext(localeContext);
  const { setOrdersVariables } = useContext(newOrdersContext);
  const { selectedStore, branches, settings } = useContext(userContext);
  const notifications = useContext(notificationsContext);
  const manualOrderSubdomain = process.env.REACT_APP_MANUAL_ORDER_URL;
  const isMobile = useMobile();
  const isSingleBranch = branches && branches.length === 1;
  const [query, setQuery] = useQueryState(initialOrderQuery);
  const [filters, setFilters] = useState(initalOrderFilters(query));
  const [selectedStatus, setSelectedStatus] = useState(
    paymentStatus || status || (statuses && statuses[0]) || (paymentStatuses && paymentStatuses[0]),
  );
  const isBranchQueryIdValid = branches.some(branch => branch.id === query.branch);

  const selectedStoreId = useSelectedStore();
  const { data: drivers } = useFetchVerdDrivers({ storeId: selectedStoreId, isHidden: false });

  const adjustedDriversList = drivers?.map(driver => ({ value: driver.id, title: driver.name }));

  useEffect(() => {
    if (!status && !statuses) {
      setSelectedStatus(null);
    }
  }, [status, statuses]);

  useEffect(() => {
    if (isSingleBranch) {
      const singleBranch = branches[0];
      setQuery({ branch: singleBranch.id });
    }
    if (!isBranchQueryIdValid) {
      setQuery({ branch: FRAME_ENUM.ALL });
    }
  }, [branches, isSingleBranch]);

  useEffect(() => {
    if (
      (paymentStatuses && paymentStatuses[0] === ORDER_STATUS.WAITING_FOR_PAYMENT) ||
      (paymentStatuses && paymentStatuses[1] === FRAME_ENUM.REDIRECT_URL)
    ) {
      setSelectedStatus(ORDER_STATUS.WAITING_FOR_PAYMENT);
    } else if (
      (paymentStatuses && paymentStatuses[0] === ORDER_STATUS.PAYMENT_FAILED) ||
      (paymentStatuses && paymentStatuses[1] === ORDER_STATUS.PAYMENT_EXPIRED)
    ) {
      setSelectedStatus(ORDER_STATUS.PAYMENT_FAILED);
    }
  }, [paymentStatuses]);

  const selectedBranch = branches.find(item => item.id === query.branch);
  const numberOfBusyBranches = handleNumberOfBusyBranches(branches);
  const [areasFilterData, setAreasFilterData] = useState([]);

  const [isBusy, setIsBusy] = useState(selectedBranch ? selectedBranch.busy : false);

  const ordersVariables = fetchOrderVariables(query, status, paymentStatus, paymentStatuses, storeId, statuses);
  const orders = useQuery(ORDERS, {
    variables: ordersVariables,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });
  const [paymentMethods, setPaymentMethods] = useState([]);

  useQuery(GET_PAYMENT_METHODS, {
    variables: { restaurantId: Number(storeId) },
    onCompleted: data => {
      const fetchedPaymentMethods = data.paymentMethods.paymentMethods;
      setPaymentMethods(trimPaymentMethods(fetchedPaymentMethods));
    },
  });

  const [fetchBranchRelatedAreas] = useLazyQuery(RESTAURANT_AREAS, {
    onCompleted: data => {
      setAreasFilterData(data.restaurantAreas);
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });

  // Dzms zones
  const { data: zones, isLoading: isZonesLoading, error: zonesError } = useFetchDeliveryZonesShort(
    settings.useDeliveryzonesMs
      ? {
          restaurantReferenceId: storeId,
          branchReferenceId: (selectedBranch && Number(selectedBranch.id)) || null,
        }
      : undefined,
  );

  useEffect(() => {
    // checking if store use new delivery zone system (useDeliveryzonesMs)
    // NOTE later we will migrate all stores to new system
    if (!isZonesLoading && settings.useDeliveryzonesMs) {
      const convertedZones = zones?.map(zoneItem => ({
        id: zoneItem.id.toString(),
        titleAr: zoneItem.zoneName,
        titleEn: zoneItem.zoneName,
      }));
      setAreasFilterData(convertedZones);
    }
    if (zonesError && settings.useDeliveryzonesMs) {
      setAreasFilterData([]);
    }
  }, [isZonesLoading, zones, zonesError]);

  const [searchValue, setSearchValue] = useState(query.search_value);
  const [filtersOpened, setFiltersOpened] = useState(false);
  const [mobileFiltersOpened, setMobileFiltersOpened] = useState(false);

  const range = parseRange(filters.range, selectedStore.createdAt);
  const filtersModalRef = useRef();
  useClickOutside(filtersModalRef, () => setFiltersOpened(false));

  useEffect(() => {
    const branchBusyTime = calcBusyTime(selectedBranch, selectedStore.timeZone);
    setBusyTime(branchBusyTime);
    setAllBranches(query.branch);
    setBusyMode(translateBusyMode(selectedBranch?.busyMode, translate));
    setIsBusy(!!(selectedBranch && selectedBranch.busy && branchBusyTime));
    // checking if store use new delivery zone system (useDeliveryzonesMs)
    // NOTE later we will migrate all stores to new system
    if (!settings.useDeliveryzonesMs) {
      const areasFilterVariables = {
        restaurantId: Number(storeId),
        branchId: (selectedBranch && Number(selectedBranch.id)) || null,
      };
      fetchBranchRelatedAreas({ variables: areasFilterVariables });
    }
  }, [query.branch]);

  useEffect(() => {
    setBusyTime(calcBusyTime(selectedBranch, selectedStore.timeZone));
  }, [selectedBranch, lang]);

  useEffect(() => {
    setBusyMode(translateBusyMode(selectedBranch?.busyMode, translate));
  }, [lang, busyTime]);

  useEffect(() => {
    if (!query.search_value && searchValue) {
      setSearchValue('');
    }
  }, [query.search_value]);

  useEffect(() => {
    setExternalVariables(ordersVariables);
  }, [query]);

  useEffect(() => {
    if (
      query.date === ORDER_STATUS.ALL &&
      query.branch === ORDER_STATUS.ALL &&
      query.payment_methods.length === 0 &&
      query.delivery_type.length === 0 &&
      query.areas.length === 0 &&
      !query.search_value
    )
      setCanExportWFilters(false);
    else setCanExportWFilters(true);
  }, [query.date, query.payment_methods, query.delivery_type, query.areas, query.search_value, query.branch]);

  useEffect(() => {
    setOrdersVariables(ordersVariables);
  }, [ordersVariables]);

  const allTabsItems = fetchAllTabsItems(orders, setSelectedStatus, replace, replaceParams, storeId);
  const dateOptions = fetchDateOptions(range, notifications, setFilters, filters, selectedStore.timeZone);
  const isEditAvailablityButton = (selectedBranch && isBusy) || (!selectedBranch && numberOfBusyBranches > 0);
  const getQueryTitles = (filterElements, selectedKeys) =>
    filterElements.filter(element => selectedKeys.includes(element.id || element.value));

  const getSelectedAreaTitles = () => {
    const selectedAreaIds =
      typeof query.areas === 'string' ? [parseInt(query.areas)] : query.areas.map(area => parseInt(area));
    const filteredAreas = prepareAreasFilter(areasFilterData, lang);
    return filteredAreas.filter(area => selectedAreaIds.includes(parseInt(area.id)));
  };
  const getSelectedZoneTitles = () => {
    const selectedAreaIds =
      typeof query.delivery_zone_in === 'string'
        ? [parseInt(query.delivery_zone_in)]
        : query.delivery_zone_in.map(zone => parseInt(zone));
    const filteredAreas = prepareAreasFilter(areasFilterData, lang);
    return filteredAreas.filter(area => selectedAreaIds.includes(parseInt(area.id)));
  };

  const getSelectedDatesTitles = () => dateOptions.filter(date => date.value === query.date);
  return (
    <Modal>
      {({ open, close }) => (
        <>
          <Style />
          <div className={cx(isMobile ? 'mb-2' : 'mb-4')}>
            <div className={cx('flex', lang === 'ar' && 'flex-row-reverse', 'items-start')}>
              <div className={cx('flex flex-col w-full', lang === 'ar' ? 'md:flex-row-reverse' : 'md:flex-row')}>
                {!isMobile && (
                  <BranchesDropDown
                    branches={branches}
                    onSelect={option => setQuery({ branch: option })}
                    currentOption={query.branch}
                    lang={lang}
                  />
                )}
                <div className={cx(!isMobile && 'px-2', 'flex flex-row')}>
                  <Slideover
                    isOpen={isManualOrderSlideoverOpened}
                    setSlideOver={setManualOrderSlideoverOpened}
                    title={<Text value={translations.SELECT_CUSTOMER} />}
                    body={<ManualOrder />}
                    subdomain={manualOrderSubdomain}
                  />

                  {isMobile && (
                    <div className="flex flex-col w-full">
                      <div className="w-full my-4 flex items-center">
                        <div
                          style={{ direction }}
                          className={cx('flex items-center sm:mt-2 md:mt-0', lang === 'ar' ? 'ml-4' : 'mr-4')}
                        >
                          <OrderFrameSetAsBusyButton
                            isEditAvailablityButton={isEditAvailablityButton}
                            lang={lang}
                            open={open}
                            selectedBranch={selectedBranch}
                            branches={branches}
                            selectedStore={selectedStore}
                            close={close}
                            setIsBusy={setIsBusy}
                          />
                        </div>
                        <BranchesDropDown
                          branches={branches}
                          isMobile={isMobile}
                          onSelect={option => setQuery({ branch: option })}
                          currentOption={query.branch}
                          lang={lang}
                        />
                      </div>
                      <div
                        className={cx(
                          'w-full flex',
                          lang === 'ar' && 'flex-row-reverse',
                          !isMobile && (lang === 'ar' ? 'ml-4' : 'mr-4'),
                        )}
                      >
                        {/* search input  */}
                        <OrderFrameSearchInput
                          searchValue={searchValue}
                          setSearchValue={setSearchValue}
                          setQuery={setQuery}
                          query={query}
                          replace={replace}
                          lang={lang}
                        />
                      </div>
                    </div>
                  )}

                  {!isMobile && (
                    <OrderFrameBusyStatus
                      allBranches={allBranches}
                      busyTime={busyTime}
                      busyMode={busyMode}
                      numberOfBusyBranches={numberOfBusyBranches}
                      selectedBranch={selectedBranch}
                      isMobile={isMobile}
                    />
                  )}
                  {!isMobile && (
                    <div
                      style={{ direction }}
                      className={cx(
                        'flex items-center mt-4 pr-4 sm:mt-2 md:mt-0',
                        lang === 'ar' ? 'md:mr-4' : 'md:ml-4',
                      )}
                    >
                      {/* render set as busy button */}
                      <OrderFrameSetAsBusyButton
                        isEditAvailablityButton={isEditAvailablityButton}
                        lang={lang}
                        open={open}
                        selectedBranch={selectedBranch}
                        branches={branches}
                        selectedStore={selectedStore}
                        close={close}
                        setIsBusy={setIsBusy}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="mb-4">
            <div className={cx('flex', lang === 'ar' && 'flex-row-reverse')}>
              {!isMobile && (
                <div className={cx('w-full flex relative', lang === 'ar' && 'flex-row-reverse')}>
                  {/* redner filters in desktop */}

                  <OrderFrameDesktopFilters
                    filtersModalRef={filtersModalRef}
                    filtersOpened={filtersOpened}
                    lang={lang}
                    setFiltersOpened={setFiltersOpened}
                    paymentMethods={paymentMethods}
                    filters={filters}
                    query={query}
                    setFilters={setFilters}
                    dateOptions={dateOptions}
                    areasFilterData={areasFilterData}
                    setQuery={setQuery}
                    selectedStore={selectedStore}
                    driversList={adjustedDriversList}
                  />
                  <div
                    className={cx(
                      'w-full flex',
                      lang === 'ar' && 'flex-row-reverse',
                      !isMobile && (lang === 'ar' ? 'ml-4' : 'mr-4'),
                    )}
                  >
                    {/* search input */}
                    <OrderFrameSearchInput
                      searchValue={searchValue}
                      setSearchValue={setSearchValue}
                      setQuery={setQuery}
                      query={query}
                      replace={replace}
                      lang={lang}
                    />
                  </div>
                </div>
              )}

              <div
                className={cx(
                  'mt-4 md:mt-2 lg:mt-0 flex flex-shrink-0',
                  lang === 'ar' && 'flex-row-reverse',
                  isMobile ? 'w-full' : 'flex-wrap justify-end',
                )}
              >
                {/* renders filters in case of mobile */}
                {isMobile && (
                  <OrderFrameMobileFilters
                    setMobileFiltersOpened={setMobileFiltersOpened}
                    mobileFiltersOpened={mobileFiltersOpened}
                    setQuery={setQuery}
                    filters={filters}
                    paymentMethods={paymentMethods}
                    areasFilterData={areasFilterData}
                    lang={lang}
                    setFilters={setFilters}
                    filtersOpened={filtersOpened}
                    dateOptions={dateOptions}
                    selectedStore={selectedStore}
                  />
                )}
                <OrderFrameSortByDropdown
                  isMobile={isMobile}
                  lang={lang}
                  query={query}
                  setQuery={setQuery}
                  canExportWFilters={canExportWFilters}
                  ordersVariables={ordersVariables}
                  storeId={storeId}
                />
              </div>
            </div>
          </div>

          <OrderFrameSelectionTags
            setQuery={setQuery}
            setFilters={setFilters}
            getQueryTitles={getQueryTitles}
            getSelectedAreaTitles={settings.useDeliveryzonesMs ? getSelectedZoneTitles : getSelectedAreaTitles}
            getSelectedDatesTitles={getSelectedDatesTitles}
            paymentMethods={paymentMethods}
            query={query}
            dateOptions={dateOptions}
            areasFilterData={areasFilterData}
            lang={lang}
            driversList={adjustedDriversList}
          />

          <div>
            {orders.data && (
              <div className="mb-4">
                {!query.search_value ? (
                  <Tabs
                    bgColor="bg-gray-100"
                    items={allTabsItems}
                    ordersVariables={ordersVariables}
                    selectedItem={selectedStatus}
                  />
                ) : (
                  <OrderFrameSearchResultsFilter
                    totalCount={orders.data.orders.totalCount}
                    query={query}
                    selectedStatus={selectedStatus}
                    replace={replace}
                    isMobile={isMobile}
                  />
                )}
              </div>
            )}
            <context.Provider
              value={{
                query,
                branches,
                orders,
                ordersVariables,
                status,
                statuses,
                paymentStatus,
                showOrderStatus: query.search_value ? true : showOrderStatus,
              }}
            >
              {children}
            </context.Provider>
          </div>
        </>
      )}
    </Modal>
  );
};

OrderFrameFilters.propTypes = {
  children: PropTypes.element.isRequired,
  storeId: PropTypes.string.isRequired,
  status: PropTypes.string,
  statuses: PropTypes.arrayOf(PropTypes.string),
  paymentStatus: PropTypes.string,
  paymentStatuses: PropTypes.arrayOf(PropTypes.string),
  setBusyTime: PropTypes.func,
  setAllBranches: PropTypes.func,
  busyTime: momentPropTypes.momentObj,
  setBusyMode: PropTypes.func,
  showOrderStatus: PropTypes.bool,
  setExternalVariables: PropTypes.func,
  setCanExportWFilters: PropTypes.func,
  canExportWFilters: PropTypes.bool,
  allBranches: PropTypes.string,
  busyMode: PropTypes.oneOf([PropTypes.string, PropTypes.bool]),
  setManualOrderSlideoverOpened: PropTypes.func.isRequired,
  isManualOrderSlideoverOpened: PropTypes.bool.isRequired,
};

export default OrderFrameFilters;
