import React from 'react';
import { WalletIcon, CreditCardIcon } from '@zydalabs/zac-icons-react';
import PropTypes from 'prop-types';

import { ReactComponent as DeliveryCard } from 'assets/card-delivery.svg';
import { ReactComponent as Knet } from 'assets/knet.svg';
import { ReactComponent as ApplePay } from 'assets/apple-pay.svg';
import { ReactComponent as Mada } from 'assets/mada.svg';
import { ReactComponent as Meeza } from 'assets/meeza-icon.svg';
import { ReactComponent as CashIcon } from 'assets/cash-icon.svg';
import fawry from 'assets/fawry.png';
import benefit from 'assets/benefit.png';
import { PAYMENT_METHODS_ENUMS } from 'components/common/orders/Frame/utils';

export const PaidMethodsIcon = ({ paidThrough, width = '24', height = '16' }) => {
  switch (paidThrough) {
    case PAYMENT_METHODS_ENUMS.BENEFIT:
      return <img src={benefit} width={width} height={height} />;
    case PAYMENT_METHODS_ENUMS.CASH:
      return <CashIcon width="25px" />;
    case PAYMENT_METHODS_ENUMS.CARD_ON_DELIVERY:
      return <DeliveryCard />;
    case PAYMENT_METHODS_ENUMS.CREDIT_CARD:
    case PAYMENT_METHODS_ENUMS.WALLET_AND_CREDIT_CARD:
      return <CreditCardIcon width="20px" />;
    case PAYMENT_METHODS_ENUMS.MEEZA:
      return <Meeza />;
    case PAYMENT_METHODS_ENUMS.KNET:
      return <Knet />;
    case PAYMENT_METHODS_ENUMS.APPLE_PAY:
      return <ApplePay />;
    case PAYMENT_METHODS_ENUMS.MADA:
      return <Mada />;
    case PAYMENT_METHODS_ENUMS.WALLET:
      return <WalletIcon width="22px" />;

    case PAYMENT_METHODS_ENUMS.FAWRY:
      return <img src={fawry} width={width} height={height} />;
    default:
      return '';
  }
};

PaidMethodsIcon.propTypes = {
  paidThrough: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  lang: PropTypes.string,
};
