import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { ExclamationIcon, ChevronRightIcon } from '@heroicons/react/outline';
import cx from 'classnames';
import moment from 'moment';

import { Text } from 'components/service';
import { Button } from 'components/kit';
import { context as userContext } from 'context/user';
import { context as localeContext } from 'context/locale';
import { ORDER_RIDER_STATUS } from 'constants/orderRiderStatus';
import { CheckboxAlt } from 'components/form/elements';
import * as translations from 'constants/translations';
import { calculateNewExpectedAt } from '../Table/Order/helpers';

const OrderDeliveryConfirmation = ({
  order,
  assignDriverToOrder,
  assignee,
  onConfirm,
  onCancel,
  isUnAssignCourier,
}) => {
  const { lang, direction } = useContext(localeContext);
  const isArabic = lang === 'ar';
  const [notifyUser, setNotifyUser] = useState(false);
  const {
    selectedStore: { timeZone, restaurantCourierSetting },
  } = useContext(userContext);

  const { deliveryStatus: deliveryOrderStatus, expectedAt, deliveryCourierId, deliveryCourier } = order || {};
  const selectedCourier = restaurantCourierSetting?.find(courier => Number(courier.courierId) === deliveryCourierId);
  const { courierDetails } = selectedCourier || {};
  const { displayNameAr, name, displayNameEn, supportNumber: orderDeliveryCourierSupportNumber } = courierDetails || {};

  const verd = restaurantCourierSetting?.find(courier => courier.isInternalDelivery);
  const isOrderDeliveryCourierNotVerd = parseInt(verd?.courierId) !== deliveryCourierId;
  const isCourierNotDeclinedTheRequest =
    !!deliveryCourierId && isOrderDeliveryCourierNotVerd && deliveryOrderStatus !== ORDER_RIDER_STATUS.DECLINED;

  const orderDeliveryCourierName = isArabic ? displayNameAr || name : displayNameEn || name;
  const changeFromTimeStamp = moment(expectedAt).tz(timeZone);
  const changeFrom = moment(expectedAt)
    .tz(timeZone)
    .locale(lang === 'ar' ? 'ar' : 'en-gb')
    .format('DD/MM hh:mm A');
  const changeTo = calculateNewExpectedAt(order)
    .tz(timeZone)
    .locale(lang === 'ar' ? 'ar' : 'en-gb')
    .format();
  const changeToTimeStamp = calculateNewExpectedAt(order).tz(timeZone);
  const formattedChangeTo = calculateNewExpectedAt(order)
    .tz(timeZone)
    .locale(lang === 'ar' ? 'ar' : 'en-gb')
    .format('DD/MM hh:mm A');
  const cancellationWarningText = deliveryCourier?.supportCancellation
    ? translations.CANCELLATION_COURIER_WARNING(orderDeliveryCourierName)
    : translations.NO_SUPPORT_CANCELLATION_COURIER_WARNING(orderDeliveryCourierName, orderDeliveryCourierSupportNumber);
  const showExpectedAtMessage = !isUnAssignCourier && changeToTimeStamp > changeFromTimeStamp;
  const assigneeName = isArabic
    ? assignee?.courierDetails?.displayNameAr || assignee?.courierDetails?.name
    : assignee?.courierDetails?.displayNameEn || assignee?.courierDetails?.name;

  return (
    <>
      <div className={cx('-mt-3', !isUnAssignCourier && 'mb-4')}>
        {isCourierNotDeclinedTheRequest && (
          <div
            className={cx(
              'flex items-center bg-zyda-yellow-50 px-4',
              isUnAssignCourier ? 'py-4' : 'py-2',
              isArabic && 'flex-row-reverse',
            )}
          >
            <ExclamationIcon className={cx(isArabic ? 'ml-2' : 'mr-2', 'w-6 h-6 text-zyda-red-500')} />
            <Text value={cancellationWarningText} />
          </div>
        )}

        {isCourierNotDeclinedTheRequest && !isUnAssignCourier && (
          <div className="flex pt-4 px-4">
            <span className="text-gray-500">{orderDeliveryCourierName}</span>
            <ChevronRightIcon className="w-5 h-5 mx-2" />
            <span className="text-zyda-green-600">{assigneeName}</span>
          </div>
        )}
        {showExpectedAtMessage && (
          <div className="flex flex-col py-2 px-4">
            <Text value={translations.CHANGE_EXPECTED_AT(changeFrom, formattedChangeTo)} className="font-semibold" />
            <div className="mt-4" style={{ direction }}>
              <CheckboxAlt
                title={<Text value={translations.INFORM_CUSTOMER_SMS} />}
                name="notifyUser"
                value={notifyUser}
                onChange={() => setNotifyUser(!notifyUser)}
              />
            </div>
          </div>
        )}
      </div>
      <div className="w-full flex flex-row-reverse border-t pt-4 items-center px-4">
        <Button
          onClick={() => {
            assignDriverToOrder(assignee, notifyUser, showExpectedAtMessage && changeTo);
            onConfirm();
          }}
          kind="primary"
          className="ml-4"
        >
          <Text value={translations.CONFIRM} />
        </Button>
        <Button onClick={() => onCancel()} type="button" kind="tertiary">
          <Text value={translations.CANCEL} />
        </Button>
      </div>
    </>
  );
};

OrderDeliveryConfirmation.propTypes = {
  order: PropTypes.shape({
    deliveryStatus: PropTypes.string,
    expectedAt: PropTypes.string,
    deliveryCourier: PropTypes.shape({
      isInternalDelivery: PropTypes.bool,
      supportCancellation: PropTypes.bool,
      courierDetails: PropTypes.shape({
        supportNumber: PropTypes.number,
        displayNameAr: PropTypes.string,
        displayNameEn: PropTypes.string,
        name: PropTypes.string,
      }),
    }),
  }),
  assignDriverToOrder: PropTypes.func.isRequired,
  assignee: PropTypes.shape({
    driverAssigned: PropTypes.bool,
    driverName: PropTypes.string,
    driverPhoneNumber: PropTypes.number,
    referenceId: PropTypes.string,
    externalOrderIdentifierLink: PropTypes.string,
    externalOrderIdentifierType: PropTypes.string,
    trackingLink: PropTypes.string,
    hasDriverInfo: PropTypes.bool,
    courierDetails: PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.string,
      displayNameEn: PropTypes.string,
      displayNameAr: PropTypes.string,
    }),
  }),
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  isUnAssignCourier: PropTypes.bool.isRequired,
};
export default OrderDeliveryConfirmation;
