import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import { context as localeContext } from 'context/locale';
import { context as userContext } from 'context/user';
import { context as notificationsContext } from 'context/notifications';
import CheckBox from 'components/kit/CheckBox';
import { Button, DropDown } from 'components/kit';
import { Text } from 'components/service';
import * as translations from 'constants/translations';
import { ORDER_STATUS } from 'constants/order';
import { ASSIGN_RIDER_ERROR_CODE } from 'constants/assignRidersErrors';
import { useMobile, useSelectedStore } from 'hooks';
import { useFetchVerdDrivers } from 'service/hooks';
import useBulkAssignDriverToOrders from 'service/hooks/verd/useBulkAssignDriverToOrders';
import Slideover from 'components/common/dashboard/Layout/Slideover/Slideover';
import AssignDeliveryModal from './OrderDetails/verdSection/AssignDeliveryModal';

const OrderSelectionRow = ({
  setBulkChangeStatus,
  setShowConfirmatioModal,
  selectAll,
  setAllOrders,
  isBulkChange,
  allOrders,
  selectedBranchId,
  isPickupOrderSelected,
  shouldDisableBulkChange,
  numberOfSelectedOrders,
  updateAllOrdersSelectedState,
}) => {
  const { lang } = useContext(localeContext);
  const {
    selectedStore: { restaurantCourierSetting },
    settings,
  } = useContext(userContext);
  const notifications = useContext(notificationsContext);
  const isMobile = useMobile();
  const selectedStoreId = useSelectedStore();
  const [isDeliverySlideOverOpen, setIsDeliverySlideOverOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const selectTypes = [
    { id: true, title: <Text value={translations.SELECT_ALL} /> },
    { id: false, title: <Text value={translations.CLEAR_ALL} /> },
  ];

  const { data: activeRiders, mutate: refetchVerdDrivers } = useFetchVerdDrivers({
    storeId: selectedStoreId,
    ...(selectedBranchId && { branchId: parseInt(selectedBranchId) }),
  });

  const verd = restaurantCourierSetting?.find(courier => courier.isInternalDelivery);
  const { driverMaxCapacity } = verd || {};

  const riders = activeRiders?.length ? activeRiders.filter(rider => rider.onShift) : [];
  const selectedOrders = allOrders?.filter(order => order.isSelected);
  const selectedOrdersNumbers = selectedOrders?.map(order => order.number);
  const bulkAssignDriverToOrders = useBulkAssignDriverToOrders();
  const shouldDisableAssignDelivery =
    numberOfSelectedOrders > driverMaxCapacity || shouldDisableBulkChange || isPickupOrderSelected;
  const isVerdEnabled = settings?.enableVerd;

  const handleUpdateSelectedOrders = () => {
    const updatedOrders = allOrders.map(order => {
      if (selectedOrdersNumbers.includes(order.number)) {
        return { ...order, isSelected: false };
      }
      return order;
    });
    setAllOrders(updatedOrders);
  };

  const handleBulkAssignErrorCode = errorCode => {
    switch (errorCode) {
      case ASSIGN_RIDER_ERROR_CODE.VRD02:
        notifications.show(<Text value={translations.ORDERS_NUMBERS_ERROR} />, 'error');
        break;
      case ASSIGN_RIDER_ERROR_CODE.VRD03:
        notifications.show(<Text value={translations.RIDER_BRANCH_ERROR} />, 'error');
        break;
      default:
        notifications.show(<Text value={translations.SOMETHING_WENT_WRONG} />, 'error');
    }
  };

  const handleBulkAssignDriverToOrders = async selectedDriver => {
    setIsLoading(true);
    const response = await bulkAssignDriverToOrders({
      driverId: parseInt(selectedDriver.id),
      ordersNumbers: selectedOrdersNumbers,
    });
    refetchVerdDrivers();
    setIsLoading(false);
    if (response.hasError) {
      response.errors && handleBulkAssignErrorCode(response.errors[0]?.message);
    } else {
      if (response.bulkAssignDriverToOrders?.failedOrders?.length) {
        notifications.show(
          <Text
            value={translations.FAILED_TO_ASSIGN_ERROR}
            payload={response.bulkAssignDriverToOrders?.failedOrders}
          />,
          'error',
        );
      }
      handleUpdateSelectedOrders();
    }
  };

  const driverMaxCapacityTooltip = (
    <ReactTooltip id="assignDriver" place="top" type="dark" effect="solid">
      <Text value={translations.DRIVER_MAX_CAPACITY_ERROR} />
    </ReactTooltip>
  );

  const completedOrCancelledOrdersTooltip = (
    <ReactTooltip id="assignDriver" place="top" type="dark" effect="solid">
      <Text value={translations.BULK_ASSIGN_ERROR} />
    </ReactTooltip>
  );

  const bulkChangeTooltip = (
    <ReactTooltip id="bulkChange" place="top" type="dark" effect="solid">
      <Text value={translations.BULK_CHANGE_ERROR} />
    </ReactTooltip>
  );

  return (
    <div
      className={cx(
        'flex mb-4 px-5 pb-4 border-b border-gray-300',
        isMobile ? 'flex-col' : 'items-center justify-between',
      )}
    >
      <Slideover
        isOpen={isDeliverySlideOverOpen}
        fullWidth={isMobile}
        body={
          <AssignDeliveryModal
            isBulkAssign
            mutateFetchVerdDrivers={refetchVerdDrivers}
            close={() => setIsDeliverySlideOverOpen(false)}
            handleAssignDriverToOrder={handleBulkAssignDriverToOrders}
            restaurantCouriersWithLogos={[verd]}
            riders={riders}
            isLoading={isLoading}
          />
        }
      />
      <div
        className={cx(
          'flex items-center',
          isMobile && 'pb-4 justify-between border-b border-gray-300',
          (lang === 'ar' || isMobile) && 'flex-row-reverse',
        )}
      >
        <div className={cx('flex items-center', lang === 'ar' && 'flex-row-reverse')}>
          <CheckBox
            value={selectAll}
            lang={lang}
            checkboxClassName="flex items-start sm:items-center justify-center"
            onChange={updateAllOrdersSelectedState}
            customCheck={selectAll}
          />
          <DropDown
            icon="keyboard_arrow_down"
            onSelect={selectedOption => {
              updateAllOrdersSelectedState(selectedOption);
            }}
            testId_openDropDown="select-order-selection-type"
            testId_selectedData="order-select-type"
            testId_listData="order-select-types-list"
            data={selectTypes}
            noPlaceholder
            wFull
            noLabel
            noBorder
            noPadding
            noMargin
          />
        </div>
        <Text
          className={cx(!isMobile && lang === 'en' ? 'ml-3' : 'mr-3')}
          value={translations.SELECTED_ORDERS}
          payload={numberOfSelectedOrders}
        />
      </div>
      <div className={cx(isMobile && 'pt-4 flex-col', 'flex items-center', lang === 'ar' && 'flex-row-reverse')}>
        <Text value={translations.CHANGE_TO} className={cx(lang === 'en' && 'mr-3')} />
        {numberOfSelectedOrders > driverMaxCapacity && driverMaxCapacityTooltip}
        {shouldDisableBulkChange && completedOrCancelledOrdersTooltip}
        <div className={cx('w-full flex', isMobile && 'mt-4')}>
          {isVerdEnabled && (
            <div id="assignDriver" data-tooltip-id="assignDriver">
              <Button
                className="mr-3"
                disabled={shouldDisableAssignDelivery}
                kind="outline"
                onClick={() => {
                  setIsDeliverySlideOverOpen(true);
                }}
                borderColor="border-primary-base"
                textColor="text-primary-base"
                isRounded
                isPillShape
              >
                <Text value={translations.ASSIGN_DELIVERY} />
              </Button>
            </div>
          )}
          {isBulkChange && (
            <>
              {shouldDisableBulkChange && bulkChangeTooltip}
              <div id="bulkChange" data-tooltip-id="bulkChange">
                <Button
                  className="mr-3"
                  onClick={() => {
                    setBulkChangeStatus(ORDER_STATUS.COMPLETED);
                    setShowConfirmatioModal(true);
                  }}
                  disabled={shouldDisableBulkChange}
                  kind="outline"
                  borderColor="border-primary-base"
                  textColor="text-primary-base"
                  isRounded
                  isPillShape
                >
                  <Text value={translations.COMPLETED_ORDERS} />
                </Button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

OrderSelectionRow.propTypes = {
  setBulkChangeStatus: PropTypes.func.isRequired,
  setShowConfirmatioModal: PropTypes.func.isRequired,
  selectAll: PropTypes.bool.isRequired,
  numberOfSelectedOrders: PropTypes.number.isRequired,
  updateAllOrdersSelectedState: PropTypes.func.isRequired,
  shouldDisableBulkChange: PropTypes.func.isRequired,
};
export default OrderSelectionRow;
